import React from "react"
import { I18nProvider } from "@lingui/react"
import Img from "gatsby-image"
import { graphql, Link } from "gatsby"
import catalogES from "../locales/es/messages"
import Layout from "../components/layout"
import cto from "../assets/images/home/cto.png"
import cfo from "../assets/images/home/cfo.png"
import coo from "../assets/images/home/coo.png"
import ceo from "../assets/images/home/ceo.png"
import cabify from "../assets/images/home/cabify.png"
import glovo from "../assets/images/home/glovo.png"
import letgo from "../assets/images/home/letgo.png"
import clip from "../assets/images/home/clip.png"
import konfio from "../assets/images/home/konfio.png"
import cornershopapp from "../assets/images/home/cornershopapp.png"
import rappi from "../assets/images/home/rappi.png"
import viajala from "../assets/images/home/viajala.png"
import platzi from "../assets/images/home/platzi.png"
import notco from "../assets/images/home/notco.png"
import jooycar from "../assets/images/home/jooycar.png"
import cryptomkt from "../assets/images/home/cryptomkt.png"
import uala from "../assets/images/home/uala.png"
import satellogic from "../assets/images/home/satellogic.png"
import ripio from "../assets/images/home/ripio.png"
import crehana from "../assets/images/home/crehana.png"
import chazki from "../assets/images/home/chazki.png"
import keynua from "../assets/images/home/keynua.png"
import cuyana from "../assets/images/home/cuyana.png"
import jobsity from "../assets/images/home/jobsity.png"
import inspectorio from "../assets/images/home/inspectorio.png"
import quintoandar from "../assets/images/home/quintoandar.png"
import nubank from "../assets/images/home/nubank.png"
import ifood from "../assets/images/home/ifood.png"

import { FaLink, FaMapMarkerAlt } from 'react-icons/fa';



export default ({ data }) => (
  <I18nProvider
    language="es"
    catalogs={{
      es: catalogES,
    }}
  >
    <Layout
      pageType="home"
      image="/android-chrome-512x512.png"
      lang="es"
      title="La Enciclopedia sobre STARTUPS Y EMPRENDIMIENTO"
      description="Startupedia pone a tu alcance toda la información indispensable sobre STARTUPS y EMPRENDIMIENTO con noticias y artículos sobre Empresas Emergentes."
      paths={{
        en: "/en",
        it: "/it",
        br: "/br",
        fr: "/fr",
        de: "/de",
        es: "",
      }}
    >
      <div className="wrapper mt-3">
        <div className="container">
          <h1>La Enciclopedia sobre Startups y Emprendimiento</h1>
          <p>Startup es una palabra que proviene del inglés y cuyo significado literal es "puesta en marcha". Pero qué es exactamente una Startup, qué tipos de Startups existen ... Todas estas preguntas y mucho más a continuación.</p>
          
          <h2 className="pt-3">¿Qué es una Startup?</h2>
          <p>El término Startup proviene del inglés y su traducción literal es "puesta en marcha".
          Aunque no existe una definición oficial, se podría decir que una Startup es una empresa o inicativa emprendedora con las siguientes características:
            <ul>
              <li>está basada en tecnología</li>
              <li>es escalable</li>
              <li>opera en un entorno de incertidumbre máxima</li>
            </ul>
            Además de los puntos anteriores, una empresa startup se diferencia de una empresa clásica o pyme, en que es muy dinámica y se adapta rápidamente a los cambios.
            La creación de este tipo de compañías tecnológicas ha dado lugar al concepto de "startupismo" o empredimiento que no es otra cosa que todo lo que gira alrededor de este mundo de creación de compañías que revolucionan el sector en el que aparecen. Haz click aquí para profundizar sobre <Link to="/que-es/startup/">qué son las startups</Link>.
          </p>

          <h2 className="pt-3">Roles y responsables en una Startup</h2>
          <p>En la fundación, o constitución, de este tipo de compañías forman parte dos o tres fundadores con distintos perfiles. Cada uno de ellos aporta su expertise con el fin de que la comañía sea exitosa. A continuación los roles típicos en una Startup.</p>
          <div className="row">
            <div className="text-center col-md-6 col-lg-3">
              <Link to="/que-es/ceo-director-general-chief-executive-officer/"><img src={ceo} alt="CEO" title="CEO" /></Link>
            </div>
            <div className="text-center col-md-6 col-lg-3">
              <Link to="/que-es/cto-director-de-tecnologia-chief-technology-officer/"><img src={cto} alt="CTO" title="CTO" /></Link>
            </div>
            <div className="text-center col-md-6 col-lg-3">
            <Link to="/que-es/cfo-director-financiero-chief-financial-officer/"><img src={cfo} alt="CFO" title="CFO" /></Link>
            </div>
            <div className="text-center col-md-6 col-lg-3">
            <Link to="/que-es/coo-director-de-operaciones-chief-operating-officer/"><img src={coo} alt="COO" title="COO" /></Link>
            </div>
          </div>
          
          <h2 className="pt-3">Lean Startup</h2>
          <p>El Método Lean Startup fue creado por Eric Ries y desarrollado en su libro "The Lean Startup". 
            Esta metología es utilizada por compañías de todo el mundo para abordar nuevas ideas de negocio, entender el mercado y saber cómo escalarlo. 
            Su objetivo es acortar los ciclos de desarrollo de los productos y descubrir rápidamente si un modelo empresarial propuesto es viable.
            Esto se logra adoptando una combinación de experimentación impulsada por la hipótesis empresarial, lanzamientos iterativos de productos y aprendizaje validado.
            Hoy en día es muy difícil recaudar dinero, especialmente en early stage, sin entender e implementar Lean Startup. 
            Más información sobre <Link to="/que-es/lean-startup/">qué es Lean Startup</Link></p>
          
          <h2 className="pt-3">Tipos de Startups por su modelo de negocio</h2>
          <p>Las Startups, y cualquier otro tipo de empresa, soluciona un problema determinado a un grupo de usuarios.
            Dependiendo del sector en el que se solucione ese problema las empresas de nueva creación pueden dividirse, entre otras, en:
            <ul>
              <li><strong>Startups Fintech:</strong> desarrollan su solución aplicando <Link to="/que-es/fintech-tecnologia-financiera/">tecnología financiera</Link>. Ejemplo: BNEXT</li>
              <li><strong>Startups Foodtech:</strong> se dedican a mejorar los procesos relacionados con la comida. Ejemplo: RAPPI</li>
              <li><strong>Startups Legaltech:</strong> su mercado es el de la legislación y abogacía. Ejemplo: TimeBillingX </li>
              <li><strong>Startups Healhtech:</strong> enfocado sobre todo al sector del cuidado de la tercera edad. Ejemplo: Cuidum</li>
              <li><strong>Startups Cleantech:</strong> automatizan procesos relacionados con la limpieza de textiles y hogares. Ejemplo: Wosh</li>
              <li><strong>Startups Edtech:</strong> crean soluciones innovadoras en el sector de la educación. Ejemplo: Udemy</li>
              <li><strong>Startups Insurtech:</strong> ofrecen servicios a sus clientes relacionados con los seguros. Ejemplo: Hippo</li>
              <li><strong>Startups Proptech:</strong> desarrollan tecnología enfocada en el sector inmobiliario. Ejemplo: Idealista</li>
            </ul></p>

            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
            <ins className="adsbygoogle"
                style={{display:"block", textAlign:"center"}}
                data-ad-layout="in-article"
                data-ad-format="fluid"
                data-ad-client="ca-pub-8800979330857975"
                data-ad-slot="6169923305"></ins>
            <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
            </script>

          <h2 className="pt-3">Clasificación de Startups por países</h2>
          Existen Startups innovadoras en todo el mundo. A continuación algunos ejemplos de Startups clasificadas por sus países de origen.
          <h3 className="pt-3">Startups Españolas</h3>
          <p>Hace sólo una década, los jóvenes emprendedores españoles que salían a lanzar empresas tecnológicas eran considerados locos. ¿Por qué no consiguieron un trabajo normal?</p>
          <p>Hoy en día, es tan diferente con todo un ecosistema de rápido crecimiento de <Link to="/que-es/emprendedor/">emprendedores</Link>, inversores ángeles, incubadoras de etapas iniciales y fondos de <Link to="/que-es/venture-capitalist-vc/">capital de riesgo</Link>.</p>
          <p>Pero, ¿cuáles son las mejores startups de España? ¿Cuáles son las que hay que seguir en 2021?</p>
          <div className="row pt-3">
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={letgo} alt="Letgo" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>Letgo</strong><br/>
                  moda; hogar <br/>
                  <FaLink /> https://we.letgo.com &nbsp; <FaMapMarkerAlt /> Barcelona <br/>
                  Plataforma de compra y venta de bienes de segunda mano
                </p>
              </div>
            </div>
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={glovo} alt="Glovo" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                <strong>Glovo</strong><br/>
                comida; transporte <br/>
                <FaLink /> https://glovoapp.com/es &nbsp; <FaMapMarkerAlt /> Barcelona <br/>
                La aplicación móvil a pedido conecta a los clientes con mensajeros locales independientes
                </p>
              </div>
            </div>
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={cabify} alt="Cabify" />
              <div class="card-body">
              <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                <strong>Cabify</strong><br/>
                  transporte <br/>
                <FaLink /> https://cabify.com/es &nbsp; <FaMapMarkerAlt /> Barcelona <br/>
                Aplicación web y móvil de reservas de coches

              </p>  
              </div>
            </div>
          </div>
        
          <h3>Startups Mexicanas</h3>
          <p>Aunque México no tiene todavía unicornios, el ecosistema de las nuevas empresas mexicanas está prosperando y cada año entran más emprendedores e inversores en el mercado.</p> 
          <p>Una combinación de apoyo gubernamental, programas de aceleración de startups y el aumento de la actividad de capital de riesgo han contribuido a construir el segundo ecosistema de startups más grande de América Latina, donde la inversión en startups se duplicó con creces en 2018 con respecto al año anterior.</p>
          <div className="row pt-3">
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={cornershopapp} alt="Cornershop" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>Cornershop</strong><br/>
                  comida; transporte <br/>
                  <FaLink /> https://cornershopapp.com/es-us &nbsp; <FaMapMarkerAlt /> Ciudad de México <br/>
                  Alimentos a la puerta de tu casa
                </p>
              </div>
            </div>
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={clip} alt="Clip" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>Clip</strong><br/>
                  pagos <br/>
                  <FaLink /> https://clip.mx &nbsp; <FaMapMarkerAlt /> Guadalajara <br/>
                  Agregador de tarjetas de crédito, vales y puntos
                </p>
              </div>
            </div>
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={konfio} alt="Konfío" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>Konfío</strong><br/>
                  pagos; crédito <br/>
                  <FaLink /> https://konfio.mx &nbsp; <FaMapMarkerAlt /> Ciudad de México <br/>
                  Soluciones financieras para hacer crecer negocios
                </p>
              </div>
            </div>
          </div>

         <h3>Startups Colombianas</h3>
          <p>El ecosistema de este tipo de empresas colombianas es uno de los de mayor crecimiento en América Latina gracias a las múltiples iniciativas gubernamentales que han fomentado un próspero mercado que atrae importantes inversiones internacionales. 
             A pesar de un pasado turbulento que ralentizó el desarrollo temprano, Colombia es ahora uno de los mercados regionales más interesantes para las empresas de nueva creación de América Latina, especialmente las que se centran en la tecnología.</p>
          <div className="row pt-3">
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={rappi} alt="Rappi" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>Rappi</strong><br/>
                  comida; transporte <br/>
                  <FaLink /> https://www.rappi.com &nbsp; <FaMapMarkerAlt /> Bogotá <br/>
                  Plataforma de envío de comida a domicilio
                </p>
              </div>
            </div>
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={viajala} alt="Viajala" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>Viajala</strong><br/>
                  turismo; vuelos <br/>
                  <FaLink /> https://viajala.com &nbsp; <FaMapMarkerAlt /> Medellín <br/>
                  Metabuscador de vuelos
                </p>
              </div>
            </div>
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={platzi} alt="Platzi" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>Platzi</strong><br/>
                  educación; formación <br/>
                  <FaLink /> https://platzi.com &nbsp; <FaMapMarkerAlt /> Bogotá <br/>
                  La escuela de tecnología en español más grande del mundo
                </p>
              </div>
            </div>
          </div>
          
          <h3>Startups Chilenas</h3>
          <p>A pesar de su tamaño y aislamiento geográfico del resto de América del Sur, Chile, apodado "Chilecón Valley", se encuentra entre los principales centros de startups de la región, y en 2019 fue considerado el país más innovador de América Latina.</p>
          <div className="row pt-3">
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={notco} alt="NotCo" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>NotCo</strong><br/>
                  comida <br/>
                  <FaLink /> https://notco.com &nbsp; <FaMapMarkerAlt /> Santiago <br/>
                  Compañía alimentaria que utiliza algoritmos de IA para recrear alimentos de origen animal, como la mayonesa y la leche, utilizando sólo componentes vegetales
                </p>
              </div>
            </div>
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={jooycar} alt="Jooycar" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>Jooycar</strong><br/>
                  seguros <br/>
                  <FaLink /> https://jooycar.com &nbsp; <FaMapMarkerAlt /> Santiago <br/>
                  Plataforma de seguros para automóviles
                </p>
              </div>
            </div>
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={cryptomkt} alt="cryptomkt" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>Cryptomkt</strong><br/>
                  crypto <br/>
                  <FaLink /> https://www.cryptomkt.com &nbsp; <FaMapMarkerAlt /> Santiago <br/>
                  Conecta a más de 160.000 usuarios a través de cuatro activos digitales: Bitcoin, Ethereum, Stellar y EOS
                </p>
              </div>
            </div>
          </div>
          
          <h3>Startups Argentinas</h3>
          <p>Argentina, a pesar de su larga historia de inestabilidad económica y crisis financieras, los empresarios desarrollan continuamente nuevas ideas para superar estos retos e impulsar el país hacia adelante. 
          Y como resultado el país está continuamente produciendo nuevas empresas innovadoras.</p>
          <div className="row pt-3">
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={uala} alt="Ualá" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>Ualá</strong><br/>
                  pagos<br/>
                  <FaLink /> https://www.uala.com.ar &nbsp; <FaMapMarkerAlt /> Buenos Aires <br/>
                  Una aplicación móvil de gestión financiera personal vinculada a una tarjeta de prepago Mastercard que permite a los usuarios realizar transacciones financieras
                </p>
              </div>
            </div>
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={ripio} alt="ripio" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>Ripio</strong><br/>
                  pagos; criptomonedas <br/>
                  <FaLink /> https://www.ripio.com/ar &nbsp; <FaMapMarkerAlt /> Buenos Aires <br/>
                  Empresa líder en bitcoin y pagos digitales en el ecosistema de Fintech en América Latina
                </p>
              </div>
            </div>
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={satellogic} alt="satellogic" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>Satellogic</strong><br/>
                  geoposicionamiento; mapas <br/>
                  <FaLink /> https://satellogic.com &nbsp; <FaMapMarkerAlt /> Buenos Aires <br/>
                  Compañía de análisis geoespacial especializada en satélites de observación de la Tierra
                </p>
              </div>
            </div>
          </div>

          <h3>Startups Peruanas</h3>
          <p>Perú tiene un gran potencial para la creación de empresas nuevas e innovadoras en América Latina. 
          En 2018, la penetración de Internet fue del 68% y el 62% de los peruanos usaron un teléfono móvil. 
          Sin embargo, gran parte de la infraestructura del país está anticuada, como el sistema bancario y el acceso a la red móvil. 
          Esto ha creado una alta demanda de empresas de impacto social y tecnología. 
          En los últimos años, Perú ha atraído cantidades cada vez mayores de capital de riesgo, ya que tanto el gobierno regional como los inversores internacionales reconocen el potencial del país.
          </p>
          <div className="row pt-3">
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={crehana} alt="crehana" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>Crehana</strong><br/>
                  cursos; formación <br/>
                  <FaLink /> https://www.crehana.com/es &nbsp; <FaMapMarkerAlt /> Lima <br/>
                  Plataforma de formación en línea para profesionales creativos y digitales en América Latina y España
                </p>
              </div>
            </div>
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={chazki} alt="chazki" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>Chazki</strong><br/>
                  delivery <br/>
                  <FaLink /> https://chazki.com/peru &nbsp; <FaMapMarkerAlt /> Lima <br/>
                  Plataforma logística de entrega que utiliza la inteligencia artificial para proporcionar mapas postales de zonas más remotas y abrir esas comunidades para las entregas en línea
                </p>
              </div>
            </div>
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={keynua} alt="keynua" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>Keynua</strong><br/>
                  inteligencia artificial <br/>
                  <FaLink /> https://www.keynua.com &nbsp; <FaMapMarkerAlt /> Lima <br/>
                  Fintech que usa videos de tres segundos como firmas virtuales. Con esta tecnología, la gente puede verificar su identidad y aprobar documentos en línea
                </p>
              </div>
            </div>
          </div>

          <h3>Startups Ecuatorianas</h3>
          <p>Ecuador es uno de los países más pequeños y diversos de América Latina. 
          Con una población de apenas 17 millones de habitantes, el tamaño de Ecuador ha impulsado a muchos emprendedores a salir rápidamente del país, creando empresas regionales o incluso mundiales.</p>
          <div className="row pt-3">
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={cuyana} alt="cuyana" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>Cuyana</strong><br/>
                  retail; ecommerce <br/>
                  <FaLink /> https://www.cuyana.com &nbsp; <FaMapMarkerAlt /> Quito <br/>
                  Empresa de ropa de mujer sostenible cofundada por Karla Gallardo (Ecuador) y Shilpa Shah (EE.UU.) mientras ambas estaban en la escuela de negocios
                </p>
              </div>
            </div>
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={jobsity} alt="jobsity" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>Jobsity</strong><br/>
                  software development <br/>
                  <FaLink /> https://www.jobsity.com &nbsp; <FaMapMarkerAlt /> Quito <br/>
                  Compañía de desarrollo de software nearshore con oficinas en Nueva York, Medellín y Bogotá, con sede en Quito
                </p>
              </div>
            </div>
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={inspectorio} alt="inspectorio" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>Inspectorio</strong><br/>
                  seguros <br/>
                  <FaLink /> https://supplychain.inspectorio.com &nbsp; <FaMapMarkerAlt /> Quito <br/>
                  Proporciona inspecciones de calidad de carácter técnico en fábricas y empresas para fomentar la confianza de los consumidores
                </p>
              </div>
            </div>
          </div>

          <h3>Startups Brasileñas</h3>
          <p>Con más de 200 millones de habitantes y una economía en rápido crecimiento, Brasil es un país caliente para las nuevas empresas que se dedican agresivamente a innovar y a hacerse con una parte del mercado. 
          Brasil es un mercado interesante para las companías que buscan desarrollar nuevos modelos de negocio y desarrollar un bastión empresarial para la expansión en América Latina.</p>
          <div className="row pt-3">
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={quintoandar} alt="quintoandar" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>QuintoAndar</strong><br/>
                  marketplace <br/>
                  <FaLink /> https://www.quintoandar.com.br &nbsp; <FaMapMarkerAlt /> Brasilia <br/>
                  Marketplace de alquiler de apartamentos
                </p>
              </div>
            </div>
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={ifood} alt="ifood" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>iFood</strong><br/>
                  envíos; comida <br/>
                  <FaLink /> https://www.ifood.com.br &nbsp; <FaMapMarkerAlt /> Brasilia <br/>
                  Servicio de entrega de alimentos en línea
                </p>
              </div>
            </div>
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={nubank} alt="nubank" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>Nubank</strong><br/>
                  finanzas; pagos <br/>
                  <FaLink /> https://nubank.com.br &nbsp; <FaMapMarkerAlt /> Brasilia <br/>
                  Empresa de servicios financieros que ofrece soluciones de pago, tarjetas de crédito gratuitas e inversiones de capital a clientes brasileños
                </p>
              </div>
            </div>
          </div>

          {/*<h3>Startups Alemanas</h3>
          <p></p>
          <div className="row pt-3">
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={konfio} alt="Card image cap" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>XXX</strong><br/>
                  XXX; XXX <br/>
                  <FaLink /> https://XXX &nbsp; <FaMapMarkerAlt /> XXX <br/>
                  XXX
                </p>
              </div>
            </div>
          </div>

          <h3>Startups Estadounidenses</h3>
          <p></p>
          <div className="row pt-3">
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={konfio} alt="Card image cap" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>XXX</strong><br/>
                  XXX; XXX <br/>
                  <FaLink /> https://XXX &nbsp; <FaMapMarkerAlt /> XXX <br/>
                  XXX
                </p>
              </div>
            </div>
          </div>

          <h3>Startups Francesas</h3>
          <p></p>
          <div className="row pt-3">
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={konfio} alt="Card image cap" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>XXX</strong><br/>
                  XXX; XXX <br/>
                  <FaLink /> https://XXX &nbsp; <FaMapMarkerAlt /> XXX <br/>
                  XXX
                </p>
              </div>
            </div>
          </div>

          <h3>Startups Italianas</h3>
          <p></p>
          <div className="row pt-3">
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={konfio} alt="Card image cap" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>XXX</strong><br/>
                  XXX; XXX <br/>
                  <FaLink /> https://XXX &nbsp; <FaMapMarkerAlt /> XXX <br/>
                  XXX
                </p>
              </div>
            </div>
          </div>


          <h2 className="pt-3">Recursos para Startups</h2>
          <h2 className="pt-3">Trabajar en una Startup</h2>
          */}  
        
        </div>



        <div className="container mt-3">
          <h2>Últimos artículos del Blog</h2>
          <div className="row">
            {/*<div className="col-sm-12 col-lg-9 col-xl-9">*/}
            <div className="col-12">
              <div className="container">
                <div className="row">
                  {data.allContentfulBlogPost.edges
                    .slice(0, 10)
                    .map(({ node }, index) => {
                      return (
                        <>
                          {/*<div className="col-md-6 mt-5">*/}
                          <div className="col-md-6 col-lg-4 mt-5">
                            <Link
                              to={`${getBlogPath(node.lang)}/${node.slug}/`}
                              title={`${getBlogPath(node.lang)}/${node.slug}/`}
                              style={{
                                textDecoration: "none",
                                color: "dimgrey",
                                fontSize: "0.9rem",
                              }}
                            >
                              <div className="card">
                                <Img
                                  alt={node.heroImage.title}
                                  title={node.heroImage.title}
                                  style={{ maxWidth: 450, maxHeight: 253 }}
                                  fadeIn="false"
                                  loading="eager"
                                  fluid={node.heroImage.fluid}
                                />
                                <div className="card-body">
                                  <h2 className="card-title">{node.title}</h2>
                                  <p className="card-text">{node.excerpt}</p>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </>
                      )
                    })}
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-lg-3 col-xl-3 mt-5 d-none">
              <img
                src="https://placehold.co/300x600"
                title="bla bla bla"
                alt="bla bla bla"
              />
              <div>&nbsp;</div>
              <img
                src="https://placehold.co/300x250"
                title="bla bla bla"
                alt="bla bla bla"
              />
            </div>
          </div>
          <p className="text-center mt-3"><Link to="/blog/"><strong>Ver más artículos sobre startups</strong></Link></p>
        {/* </div>
      </div>
      <div className="col-12" style={{backgroundColor:"#283747"}}>
              <div className="row" style={{color:"white"}}>
                <div className="container">
                  <h2 style={{color:"white",marginTop:"10px"}}>Términos Populares</h2>
                  <p style={{color:"darkgray"}}>ola ka ase</p>
                </div>
                 */}
              </div>
            </div>
    </Layout>
  </I18nProvider>
)

export const query = graphql`
  query {
    allContentfulBlogPost(
      limit:6
      sort: { order: DESC, fields: updatedAt }
      filter: { lang: { eq: "es" } }
    ) {
      edges {
        node {
          lang
          title
          excerpt
          description {
            description
          }
          slug
          heroImage {
            title
            description
            fluid(
              maxHeight: 253
              maxWidth: 450
              quality: 60
              cropFocus: CENTER
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`

function getBlogPath(lang) {
  return lang === "es" ? "/blog" : `/${lang}/blog`
}